



































import {defineComponent, PropType} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import TableInput from '@/tasks/components/inputs/TableInput.vue';
import STextareaShort from '@/common/components/STextareaShort.vue';
import STextarea from '@/common/components/STextarea.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default defineComponent({
  name: 'UCIrvineELN2V2QuantitativeObservations2',
  components: {STextarea, TableInput, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      inputs: {
        additionalObservations: '',
        absorbanceTableRows: [
          {
            a: '',
            b: '',
            c: '',
            d: '',
          },
          {
            a: '',
            b: '',
            c: '',
            d: '',
          },
        ],
      },
      absorbanceColumns: [
        {
          label: 'Dye Name',
          component: STextareaShort,
          prop: 'a',
          width: '25%',
        },
        {
          label: 'Concentration (µM)',
          component: STextareaShort,
          prop: 'b',
          width: '25%',
        },
        {
          label: 'λmax (nm)',
          component: STextareaShort,
          prop: 'c',
          width: '25%',
        },
        {
          label: 'Absorbance (at λmax)',
          component: STextareaShort,
          prop: 'd',
          width: '25%',
        },
      ],
      questions: [
        {
          text: 'In the space below, please insert any remaining quantitative observations for the experiment.',
          inputName: 'additionalObservations',
        },
      ],
    };
  },
  methods: {},
});
